<template>
  <VCard id="deliverable-export-card">
    <VCardText v-if="exportComplete">
      <Alerts
        v-if="hasMessagesOrErrors"
        :messages="messages"
        :errors="errors"
      />
      <VAlert
        v-else-if="exportComplete"
        type="success"
        :value="true"
      >
        Your request is running in the background. You'll receive a notification once it's done. If you don't see the export please check your Spam folders. If you still don't receive the export in 15 mins, please contact support.
        <VBtn
          large
          color="primary"
          class="my-0"
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="$v.invalid || activity.isFormLoading"
          @click="onReset(true)"
        >
          Start Over
        </VBtn>
      </VAlert>
    </VCardText>
    <VCardText v-else>
      <VSheet
        rounded
        color="grey lighten-2"
        class="px-4 py-4"
      >
        <VRow
          row
          wrap
          align-center
        >
          <VCol cols="12">
            <VAlert
              type="warning"
              :value="$route.query.deliverable_id > 0"
            >
              You have limited this export to a specific {{ featureName('deliverable').singularize() }}. To remove this <VBtn
                small
                depressed
                color="info"
                @click="onRemoveDeliverableFilter"
              >
                Click Here
              </VBtn>
            </VAlert>
            <h2 class="title mb-4">
              Configure export options here
            </h2>
            <VRow
              align-center
              slot="append-outer"
            >
              <VCol
                :order="$vuetify.breakpoint.smAndDown ? 3 : 1"
                :class="{
                  'mt-5': $vuetify.breakpoint.smAndDown
                }"
                :grow="$vuetify.breakpoint.mdAndUp"
                :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
              >
                <Autocomplete
                  v-model="filter.deliverables"
                  filled
                  multiple
                  item-text="title"
                  item-value="id"
                  append-inner-icon="arrow-down"
                  hide-details
                  :dense="false"
                  :disabled="activity.isLoading"
                  :label="`Select ${featureName('deliverables')}`"
                  :placeholder="`Select ${featureName('deliverables')}`"
                  :loading="activity.isOptionsLoading"
                  :items="filterOptions.deliverable"
                  :error="$v.form.deliverables.$error"
                  @input="$v.form.deliverables.$touch()"
                  @blur="onSyncFilterToForm"
                />
              </VCol>
              <VCol
                :order="$vuetify.breakpoint.smAndDown ? 2 : 1"
                :shrink="$vuetify.breakpoint.mdAndUp"
                :cols="$vuetify.breakpoint.smAndDown ? 6 : 'auto'"
              >
                <VBtn
                  large
                  text
                  color="dark"
                  :block="$vuetify.breakpoint.smAndDown"
                  :disabled="activity.isFormLoading"
                  @click="onReset"
                >
                  Start Over
                </VBtn>
              </VCol>
              <VCol
                :order="$vuetify.breakpoint.smAndDown ? 2 : 3"
                :shrink="$vuetify.breakpoint.mdAndUp"
                :cols="$vuetify.breakpoint.smAndDown ? 6 : 'auto'"
              >
                <VBtn
                  large
                  color="info"
                  :block="$vuetify.breakpoint.smAndDown"
                  :disabled="activity.isFormLoading || !isFormReady"
                  @click="onSave"
                >
                  Run Export
                </VBtn>
              </VCol>
            </VRow>
          </VCol>
          <VCol cols="12">
            <Autocomplete
              v-model="filter.submitted_by"
              filled
              multiple
              item-text="name"
              item-value="id"
              append-inner-icon="arrow-down"
              hide-details
              label="(Optional) Select users"
              placeholder="Select users"
              :dense="false"
              :disabled="activity.isLoading"
              :loading="activity.isOptionsLoading"
              :items="filterOptions.submittedBy"
              :getter="onGetSubmittedBy"
              @blur="onSyncFilterToForm"
            />
          </VCol>
          <VCol cols="12">
            <VCombobox
              v-model="form.emails"
              outlined
              multiple
              small-chips
              deletable-chips
              clearable
              hide-details
              label="(Optional) Send the report to these emails"
              placeholder="Enter emails"
              :disabled="activity.isLoading"
            />
          </VCol>
          <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
            <VDialog
              v-model="dialogs.submittedBetweenStart"
              width="290px"
              ref="submittedBetweenStartDialog"
              @update:return-value="onSyncFilterToForm"
            >
              <template #activator="{ on, attrs }">
                <VTextField
                  v-model="filter.submitted_between_start"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  clearable
                  hide-details
                  label="(Optional) Select Submitted Start date"
                  prepend-icon="event"
                  :disabled="activity.isLoading"
                />
              </template>
              <VDatePicker
                v-model="filter.submitted_between_start"
                scrollable
                clearable
                color="info"
                :disabled="activity.isLoading"
                @input="dialogs.submittedBetweenStart = false"
              />
            </VDialog>
          </VCol>
          <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
            <VDialog
              v-model="dialogs.submittedBetweenEnd"
              width="290px"
              ref="submittedBetweenEndDialog"
              @update:return-value="onSyncFilterToForm"
            >
              <template #activator="{ on, attrs }">
                <VTextField
                  v-model="filter.submitted_between_end"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  clearable
                  readonly
                  hide-details
                  label="(Optional) Select Submitted End date"
                  prepend-icon="event"
                  :disabled="activity.isLoading"
                />
              </template>
              <VDatePicker
                v-model="filter.submitted_between_end"
                scrollable
                clearable
                outlined
                color="info"
                :disabled="activity.isLoading"
                @input="dialogs.submittedBetweenEnd = false"
              />
            </VDialog>
          </VCol>
        </VRow>
      </VSheet>
      <Submitted
        disable-filter
        mode="embedded"
        :filter-on-filter-update="canFilterOnUpdate"
        :custom-filter="realFilter"
        @startLoading="emitLoading"
        @doneLoading="emitLoadingDone"
      />
    </VCardText>
    <Confirm ref="confirmReset" />
  </VCard>
</template>
<script>
import { mapActions } from "vuex";
import { saveAs } from "file-saver";
import ActivityMixin from "@/mixins/Activity";
import { required, minLength, between, email } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import HasPagination from "@/mixins/HasPagination";
import Submitted from "./Submitted";
import CanFilter from "@/mixins/CanFilter";

export default {
  name: "ExportSubmitted",
  mixins: [ActivityMixin, FormMixin, HasPagination, CanFilter],
  components: { Submitted },
  data() {
    return {
      enableBackgroundSearch: true,
      dialogs: {
        submittedBetweenStart: false,
        submittedBetweenEnd: false,
      },
      options: {
        deliverables: [],
        users: [],
      },
      error: "",
      message: "",
      filter: {
        deliverables: [],
        submitted_by: [],
        submitted_between_start: null,
        submitted_between_end: null,
      },
      form: {
        deliverables: [],
        emails: [],
      },
      defaultForm: {
        s: "",
        emails: [],
        deliverables: [],
        summarize: true,
        only_to_specified: false,
        submitted_type: "submitted",
        exclude_recently_notified: false,
        submitted_before: null,
        submitted_after: null,
        submitted_between_start: null,
        submitted_between_end: null,
      },
    };
  },
  validations: {
    form: {
      deliverables: {
        required,
        minLength: minLength(1),
      },
      $each: {
        emails: {
          email,
        },
      },
    },
  },
  computed: {
    realFilter() {
      return {
        filter: {
          deliverable: this.form.deliverables,
          submitted_by: this.form.submitted_by,
          submitted_between_start: this.form.submitted_between_start,
          submitted_between_end: this.form.submitted_between_end,
        },
      };
    },
    exportComplete() {
      return (this.saveResult || {}).isQueued;
    },
    hasErrorsOrMessages() {
      return this.error.length >= 0 || this.message.length > 0;
    },
    canBeAttached() {
      for (const i in this.entityTypes) {
        const entity = this.entityTypes[i];
        if (!entity instanceof Object) {
          return false;
        }
        const source = this.deliverableHasAssociations
          ? this.deliverable
          : this.options;
        if (
          source.hasOwnProperty(entity.slug) &&
          source[entity.slug] instanceof Array
        ) {
          return source[entity.slug].length > 0;
        }
      }
      return false;
    },
    selectedDeliverableHasMetadata() {
      return this.form.deliverables
        ? (this.deliverable.metadata || []).length > 0
        : false;
    },
  },
  watch: {
    "$route.query.deliverable_id": function (value) {
      if (value) {
        this.onReset(true, true);
        this.form.deliverables = this.filter.deliverables = [value];
      }
    },
  },
  created() {
    this.onGetExportConfig();
  },
  mounted() {
    this.form.emails = [this.$user.email];
    if (this.$route.query.deliverable_id) {
      this.form.deliverables = this.filter.deliverables = [
        parseInt(this.$route.query.deliverable_id),
      ];
    }
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "deliverableSubmitted/getIndexConfig",
      doExportDeliverablesSubmitted: "deliverableSubmitted/export",
      doGetUsers: "users/getAll",
      doGetDeliverables: "deliverable/getAll",
    }),
    onSearch(s) {
      return this.saved.data.filter((user) => {
        if (this.filter && this.filter.length) {
          const nameMatch = user.name.match(this.filter);
          const emailMatch = user.email.match(this.filter);
          return (
            (nameMatch && nameMatch.length > 0) ||
            (emailMatch && emailMatch.length > 0)
          );
        }
        return true;
      });
    },
    onSave() {
      return this.saveFormModel(this.doExportDeliverablesSubmitted);
    },
    onReset(force, resetSelected) {
      const resetFunc = (result, shouldResetEntities) => {
        if (result === true) {
          this.error = this.message = "";
          this.errors = this.messages = [];
          this.saveResult = null;
          this.form = { ...this.defaultForm };
          this.form.emails = [this.$user.email];
        }
      };

      if (force === true) {
        resetFunc(true, resetSelected);
      } else {
        this.$refs["confirmReset"]
          .open("Reset", "Are you sure?", {
            color: "orange",
          })
          .then((result) => {
            resetFunc(result, true);
          });
      }
    },
    onGetFormConfig() {
      return this.doGetIndexConfig().then((result) => (this.config = result));
    },
    onGetDeliverables() {
      this.$emit("doneLoading");
      this.$emit("startOptionsLoading");
      return this.doGetDeliverables({
        _withCustom: true,
        filter: {
          id: this.$route.query.deliverable_id,
        },
      })
        .then((result) => {
          this.options.deliverables = result.data;
          if (this.$route.query.deliverable_id) {
            this.form.deliverables = [
              parseInt(this.$route.query.deliverable_id),
            ];
          }
        })
        .catch(() => {
          this.$emit("doneOptionsLoading");
        })
        .then(() => {
          this.$emit("doneOptionsLoading");
        });
    },
    onGetSubmittedBy(params) {
      this.$emit("doneLoading");
      this.$emit("startOptionsLoading");
      return this.doGetUsers(params).then((result) => {
        this.options.submittedBy = result.data;
        this.$emit("doneOptionsLoading");
        return result;
      });
    },
    getUniqueKeyValue(index) {
      let item = index instanceof Object ? index : this.prepared.data[index];
      if (item) {
        const metadata = item.metadata.find(
          (item) =>
            item.name ==
            (this.uniqueKey instanceof Object
              ? this.uniqueKey.id
              : this.uniqueKey)
        );
        if (metadata) {
          return metadata.value;
        }
      }
      return "None";
    },
    onPaginatePreparedLocal(pagination) {
      this.preparedPaginated = {
        ...this.preparedPaginated,
        ...this.getLocalDataPaginated(pagination, "prepared"),
      };
    },
    onPaginateSavedLocal(pagination) {
      this.savedPaginated = {
        ...this.savedPaginated,
        ...this.getLocalDataPaginated(pagination, "saved"),
      };
    },
    onUpdatePaginationPreparedLocal(pagination) {
      this.onPaginateLocal(pagination, "preparedPaginated");
      this.onPaginatePreparedLocal(pagination);
    },
    onUpdatePaginationSavedLocal(pagination) {
      this.onPaginateLocal(pagination, "savedPaginated");
      this.onPaginateSavedLocal(pagination);
    },
    onRemoveDeliverableFilter() {
      delete this.$route.query.deliverable_id;
      this.onGetExportConfig();
    },
  },
};
</script>