var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"column":""}},[_c('VCol',{staticStyle:{"flex":"0 0 64px"}},[_c('IndexHeader',{attrs:{"mode":"standalone","can-create":_vm.$isUserAdmin || _vm.checkMentorPermission(_vm.modelType)},scopedSlots:_vm._u([{key:"actions-left",fn:function(){return [(_vm.$isUserAdmin || _vm.checkMentorPermission(_vm.modelType))?_c('ExpandableButton',{attrs:{"icon":"","text":"Import","button-class":"","color":"info","depressed":true,"large":true,"flex":true,"block":_vm.$vuetify.breakpoint.smAndDown,"to":{
            hash: '#import'
          },"tooltip-text":`Import ${_vm.featureName('deliverables').toLowerCase()}`}}):_vm._e(),(!_vm.$isUserStudent)?_c('ExpandableButton',{attrs:{"icon":"","text":"Export","button-class":"ml-2","color":"info","beta-feature":"deliverable_submitted_export","depressed":true,"large":true,"flex":true,"block":_vm.$vuetify.breakpoint.smAndDown,"beta-options":{
            asIcon: false,
            overlap: true,
            offsetY: '15',
            offsetX: '15',
            class: _vm.isBetaFeature('deliverable_submitted_export') ? 'mr-2' : ''
          },"to":{
            hash: '#export'
          },"tooltip-text":`Export ${_vm.featureName('deliverables').toLowerCase()}`}}):_vm._e()]},proxy:true}])},[_c('template',{slot:"create-action"},[(_vm.$isUserAdmin || _vm.checkMentorPermission(_vm.modelType))?_c('ExpandableButton',{attrs:{"large":"","icon":"add","button-class":"wethrive px-6","button-style":"display: grid;","to":{
            name: `${_vm.modelType.singularize()}.create`,
            params: {
              team: _vm.$team.slug,
            },
          },"tooltip-text":`Add ${_vm.featureName(_vm.modelType.capitalize(), 'singularize')}`}}):_vm._e()],1)],2)],1),_c('VCol',{attrs:{"mb-2":"","shrink":""}},[_c('Stats',{attrs:{"stats":_vm.tabRefConfig.stats,"options":_vm.statOptions}})],1),_c('VCol',{staticStyle:{"flex":"1 1 auto"}},[_c('VTabs',{staticClass:"d-none",attrs:{"lazy":"","grow":"","slider-color":"primary"}},_vm._l((_vm.tabs),function(tab,key){return _c('VTab',{key:key,attrs:{"to":{
          hash: tab.url
        },"lazy":""}},[_c('BetaBadge',{attrs:{"is-beta":_vm.isBetaFeature(tab.feature),"enable-tooltip":""}},[_vm._v(" "+_vm._s(tab.title)+" ")])],1)}),1),_c('VTabsItems',{attrs:{"touchless":"","value":_vm.activeUrlTab}},[_c('VTabItem',[_c('Data',{ref:"data",attrs:{"existing-config":_vm.config},on:{"setStatsData":_vm.setStatsData}})],1),(_vm.$isUserAdmin || _vm.checkMentorPermission(_vm.modelType))?_c('VTabItem',[_c('Import',{ref:"imported"})],1):_vm._e(),(_vm.$isUserAdmin || _vm.$isUserMentor)?_c('VTabItem',[_c('Export',{ref:"exported"})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }