<template>
  <VRow column>
    <VCol style="flex: 0 0 64px">
      <IndexHeader
        mode="standalone"
        :can-create="$isUserAdmin || checkMentorPermission(modelType)"
      >
        <template slot="create-action">
          <ExpandableButton
            v-if="$isUserAdmin || checkMentorPermission(modelType)"
            large
            icon="add"
            button-class="wethrive px-6"
            button-style="display: grid;"
            :to="{
              name: `${modelType.singularize()}.create`,
              params: {
                team: $team.slug,
              },
            }"
            :tooltip-text="`Add ${featureName(modelType.capitalize(), 'singularize')}`"
          />
        </template>
        <template #actions-left>
          <ExpandableButton
            v-if="$isUserAdmin || checkMentorPermission(modelType)"
            icon
            text="Import"
            button-class
            color="info"
            :depressed="true"
            :large="true"
            :flex="true"
            :block="$vuetify.breakpoint.smAndDown"
            :to="{
              hash: '#import'
            }"
            :tooltip-text="`Import ${featureName('deliverables').toLowerCase()}`"
          />
          <ExpandableButton
            v-if="!$isUserStudent"
            icon
            text="Export"
            button-class="ml-2"
            color="info"
            beta-feature="deliverable_submitted_export"
            :depressed="true"
            :large="true"
            :flex="true"
            :block="$vuetify.breakpoint.smAndDown"
            :beta-options="{
              asIcon: false,
              overlap: true,
              offsetY: '15',
              offsetX: '15',
              class: isBetaFeature('deliverable_submitted_export') ? 'mr-2' : ''
            }"
            :to="{
              hash: '#export'
            }"
            :tooltip-text="`Export ${featureName('deliverables').toLowerCase()}`"
          />
        </template>
      </IndexHeader>
    </VCol>
    <VCol
      mb-2
      shrink
    >
      <Stats
        :stats="tabRefConfig.stats"
        :options="statOptions"
      />
    </VCol>
    <VCol style="flex: 1 1 auto">
      <VTabs
        lazy
        grow
        slider-color="primary"
        class="d-none"
      >
        <VTab
          v-for="(tab, key) in tabs"
          :to="{
            hash: tab.url
          }"
          :key="key"
          lazy
        >
          <BetaBadge
            :is-beta="isBetaFeature(tab.feature)"
            enable-tooltip
          >
            {{ tab.title }}
          </BetaBadge>
        </VTab>
      </VTabs>
      <VTabsItems
        touchless
        :value="activeUrlTab"
      >
        <VTabItem>
          <Data
            ref="data"
            :existing-config="config"
            @setStatsData="setStatsData"
          />
        </VTabItem>
        <!-- <VTabItem>
          <Submitted ref="submitted" />
        </VTabItem> -->
        <VTabItem v-if="$isUserAdmin || checkMentorPermission(modelType)">
          <Import ref="imported" />
        </VTabItem>
        <VTabItem v-if="$isUserAdmin || $isUserMentor">
          <Export ref="exported" />
        </VTabItem>
      </VTabsItems>
    </VCol>
  </VRow>
</template>

<script>
import HasStatsMixin from "@/mixins/HasStats";
import Submitted from "./Submitted";
import Data from "./Data";
import Import from "./Import";
import Export from "./Export";
import { mapActions } from "vuex";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";
import HasTabsMixin from "@/mixins/HasTabs";

export default {
  name: "DeliverablesIndex",
  components: {
    Data,
    // Submitted,
    IndexHeader,
    Import,
    Export,
  },
  mixins: [HasStatsMixin, HasTabsMixin],
  data() {
    return {
      date: null,
      modelType: "deliverable",
      statOptions: {
        inflect: {
          submitted: {
            plural: "Submitted",
          },
        },
      },
      config: {
        stats: {
          "-": "-",
        },
      },
      refNames: ["data", "submitted", "imported"],
    };
  },
  computed: {
    tabs() {
      return [
        {
          title: this.featureName("Deliverables"),
          url: "",
          enabled: true,
        },
        // {
        //   title: "Submitted",
        //   url: "#submitted",
        //   enabled: true,
        // },
        {
          title: "Import Submitted",
          url: "#import",
          enabled: this.$isUserAdmin || this.checkMentorPermission(this.modelType),
        },
        {
          title: "Export Submitted",
          url: "#export",
          enabled: this.$isUserAdmin || this.$isUserMentor,
          feature: "deliverable_submitted_export",
        },
      ].filter((t) => t.enabled);
    },
  },
  methods: {
    setStatsData(stats) {
      this.tabRefConfig.stats = stats;
    }
  }
};
</script>

